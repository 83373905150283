<template>
  <main class="blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <header>
      <h2>ביטול טעינת פרייפייד</h2>
    </header>

    <h2 class="mx-5 mb-3">פרטי הטעינה</h2>

    <div class="page-content">
      <v-data-table
                :headers="headers"
                :items="table"
                class="elevation-1"
                show-select
            >
                <template v-slot:items="props">
                    <tr>
                        <td>
                            <v-checkbox
                            :input-value="props.selected"
                            primary
                            hide-details
                            ></v-checkbox>
                        </td>
                        <td class="text-xs-right abc">{{ props.item.e }}</td>
                    </tr>
                </template>
            </v-data-table>

            <div class="d-flex my-5">
              <div>
                <input type="checkbox" id="aaa">
                <label class="ml-3" for="aaa">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</label>
              </div>

              <button class="btn btn-void ml-auto">בטל</button>
            </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    let names = ["a","b","c","d", "e", "f","g", "m", "l", "n"]

    return {
      headers: ["שם העסק", "נייד", "ספק", 'מוצר','תאריך', 'תאריך', 'מחיר לצרכן', 'מחיר עלות'].map((i,idx) => ({text: i, value: names[idx] })),
      table: [{
            a: "1",
            b: "2",
            c: "3",
            d: "4",
            e: "5",
            f: "6",
            g: "7",
            h: "8"
        }]
    }
  }
}
</script>

<style lang="scss" scoped>
    header > h2 {
        text-align: center;
        padding: 16px 0;
    }
    .page-content {
        padding-left: 16px;
        background: white;
    }

    div.search-bar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 8px;
        margin: 16px 0;
        background: none;
    }

    div.search-input {
        position: relative;
        &:before {
            content: '';
            background: url('/assets/img/search.svg');
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 8px;
            width: 20px;
            height: 20px;
        }
        > input {
            padding: 0px;
            padding-left: 36px;
        }
    }

    div.calendar-input {
        position: relative;
        &:before {
            content: '';
            background: url('/assets/img/calendar-2.svg');
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 8px;
            width: 20px;
            height: 20px;
            z-index: 1;
        }
        > div.input {
            padding: 0px;
            padding-left: 36px;
        }
    }

    div.submit {
        button {
            padding: 8px 16px;
            background: #29abe2;
            border-radius: 6px;
            color: white;
        }
    }

    .btn-void {


      width: 423px;
      height: 44px;

      color: var(--unnamed-color-ffffff);
      text-align: center;
      font: normal normal bold 20px/27px Open Sans Hebrew;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      background: #E22929;
    }
</style>